"use client";

import Lottie from "react-lottie-player";

import lottieJson from "@assets/img/vd_loader.json";

export default function Loader() {
	return (
		<div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-base-300">
			<Lottie loop animationData={lottieJson} play style={{ width: 150, height: 150 }} />
		</div>
	);
}
